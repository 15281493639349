













import { Station } from '@/model/Station'
import { TimeSeries } from '@/model/TimeSeries'
import { IStationService } from '@/services/station/IStationService'
import { DateTime, Interval } from 'luxon'
import { Layout, newPlot, PlotData } from 'plotly.js'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

const CLOSE_ICON = {
    color: '#ffffff',
    path: 'M256,33C132.3,33,32,133.3,32,257c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C480,133.3,379.7,33,256,33z    M364.3,332.5c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.2-2.3,5.6l-21.6,21.7c-1.6,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3L256,289.8   l-75.4,75.7c-1.5,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.2,2.3-5.6l75.7-76   l-75.9-75c-3.1-3.1-3.1-8.2,0-11.3l21.6-21.7c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l75.7,74.7l75.7-74.7   c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l21.6,21.7c3.1,3.1,3.1,8.2,0,11.3l-75.9,75L364.3,332.5z',
    transform: 'matrix(1 0 0 1 -2 -2) scale(0.05)'
}

@Component
export default class StationChart extends Vue {
    @Prop() station!: Station
    @Prop() timestamp!: DateTime
    @Prop() timeperiod!: Interval

    @Ref('chart') chart!: HTMLDivElement

    private loaded = false
    private hasTimeSeries = false
    private stationService!: IStationService

    created(): void {
        this.stationService = this.$services.get<IStationService>('stations')
    }

    async mounted(): Promise<void> {
        const timeseriesCollection = []

        if (this.timestamp != null) {
            timeseriesCollection.push(
                ...(await this.stationService.getTimeSeriesForStation(this.station, this.timestamp))
            )
        } else if (this.timeperiod != null) {
            timeseriesCollection.push(
                ...(await this.stationService.getTimeSeriesForStationByPeriod(
                    this.station,
                    this.timeperiod.start,
                    this.timeperiod.end
                ))
            )
        }

        this.loaded = true
        this.hasTimeSeries = timeseriesCollection.length > 0

        for (const timeseries of timeseriesCollection) {
            newPlot(this.chart, this.mapToPlotData(timeseries), this.getLayoutFor(this.station, timeseries), {
                modeBarButtonsToRemove: [
                    'toImage',
                    'autoScale2d',
                    'zoom2d',
                    'pan2d',
                    'select2d',
                    'lasso2d',
                    'zoomIn2d',
                    'zoomOut2d',
                    'resetScale2d'
                ],
                modeBarButtonsToAdd: [
                    {
                        title: 'Close',
                        name: 'closeButton',
                        icon: CLOSE_ICON,
                        click: this.onCloseRequested
                    }
                ]
            })
        }
    }

    mapToPlotData(timeseries: TimeSeries): PlotData[] {
        const plotData: PlotData[] = []
        if (timeseries.hasHistory) {
            plotData.push({
                x: [...timeseries.history].map((ts) => ts.setZone(this.getZoneName()).toString()),
                y: timeseries.history.getValues(),
                hovertemplate: `%{y} ${timeseries.measureKind.unit}`,
                mode: 'lines',
                name: this.$t('components.stationchart.historical'),
                line: {
                    color: '#1F77B4'
                }
            } as PlotData)
        }
        if (timeseries.hasForecasts) {
            const boundary = timeseries.history.getLastTimestamp().setZone(this.getZoneName()).toString()
            plotData.push({
                type: 'scatter',
                x: [boundary, boundary],
                y: [0, 50],
                hoverinfo: 'none',
                mode: 'lines',
                line: {
                    color: 'lightgrey'
                }
            } as PlotData)
            plotData.push({
                x: [timeseries.history.getLastTimestamp(), ...timeseries.forecasts].map((timeserie) =>
                    timeserie.setZone(this.getZoneName()).toString()
                ),
                y: [timeseries.history.getLastValue(), ...timeseries.forecasts.getValues()],
                hovertemplate: `%{y} ${timeseries.measureKind.unit}`,
                mode: 'lines',
                name: this.$t('components.stationchart.forecasted'),
                line: {
                    color: 'orange'
                }
            } as PlotData)
        }

        return plotData
    }

    private getZoneName(): string {
        if (this.timestamp) {
            return this.timestamp.zoneName
        } else if (this.timeperiod) {
            return this.timeperiod.start.zoneName
        } else {
            return 'utc'
        }
    }

    private onCloseRequested(): void {
        this.stationService.toggleStation(this.station)
    }

    private getLayoutFor(station: Station, timeSeries: TimeSeries): Layout {
        return {
            title: `Station ${station.name}`,
            width: 544,
            height: 300,
            yaxis: {
                visible: true,
                showticklabels: true,
                range: [...station.getWaterLevels()]
            },
            xaxis: {
                range: [
                    timeSeries.getStart(this.getZoneName()).toString(),
                    timeSeries.getEnd(this.getZoneName()).toString()
                ],
                visible: true,
            },
            legend: {
                x: 0.0,
                y: -1,
                xanchor: 'center',
                orientation: 'h'
            },
            showlegend: false,
            hovermode: 'x'
        } as Layout
    }
}
